import React, { useState } from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import axios from 'axios';
import PopupDelete from '../popup_delete';

const VendorDelete = ({ vendor_id , removeVendorFromList }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleDelete = async (event) => {

      const response = await axios.delete(`/api/v2/vendors/${vendor_id}/`);

      try {
        if (response.status === 204) {
          removeVendorFromList(vendor_id);
          onClose();
        } else {
          setError(response['request']['responseText'])
        }
      } catch (error) {
        setError('There was an error submitting the form!');
      } finally {
        setLoading(false);
      }
  };

  return (
      <div>
          <Button colorScheme="red" onClick={onOpen}>Delete</Button>
          <PopupDelete
              isOpen={isOpen}
              onClose={onClose}
              title="Confirm Delete"
              body="Are you sure you want to delete this vendor?"
              confirmText="Delete"
              confirmAction={handleDelete}
              loading={loading}
              errorMessage={error}
          />
      </div>
  );
};

export default VendorDelete;

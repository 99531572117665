import React from "react"
import { Card, CardHeader, CardBody, Flex, Divider, Heading, Text, VStack } from "@chakra-ui/react"
import { renderAmount } from "../../utils"

export const BankAccountCard = ({bankName, bankAccount, variant, onClick, balance}) => {
  return (
    <Card m="5" minW="350px" maxW="350px" variant={variant} onClick={onClick}>
      <CardHeader p="5">
        <Heading m="0" size='sm'>{bankName}: {bankAccount.name}</Heading>
      </CardHeader>
      <CardBody pt="0">
        <Flex>
          <VStack flex="1" spacing="0px" align={"start"}>
            <Text>Bank Balance</Text>
            <Text>{renderAmount(bankAccount.balance_current)}</Text>
          </VStack>
          <Divider size='1px' color="gray.50" orientation='vertical' />
          <VStack flex="1" spacing="0px" align={"start"}>
            <Text>Lucro Balance</Text>
            <Text>{renderAmount(balance)}</Text>
          </VStack>
        </Flex>
        <Text m="0">{bankAccount.transaction_review_count} transactions for review</Text>
      </CardBody>
    </Card>
  )
}

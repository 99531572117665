import React from 'react';
import {
    Button,
    Stack,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    Text,
    Box,
} from '@chakra-ui/react'
import { Select } from "chakra-react-select"
import { BankAccountCard } from './account/bankAccountCard'
import { Context } from '../ContextWrapper';

export default function ThemeView() {
    const context = React.useContext(Context);

    const bankAccount = {
        accounting_account: "2d823674-8c92-4609-b599-1a4c24d15824",
        id: "e5d9bcd6-c6eb-4a41-a54f-b93aab7d8323",
        name: "Plaid Saving",
        official_name: "Plaid Silver Standard 0.1% Interest Saving",
        plaid_linked: true,
        quickbooks_name: "quickbook savings",
        subtype: "savings",
        type: "depository",
    }

    return (
        <Box bg="brand.50">
            <Text p="3" fontSize='3xl'>Bank Account Card</Text>
            <Stack p="5" direction='row' spacing={4} align='center'>
                <BankAccountCard bankName="Chase" bankAccount={bankAccount} />
                <BankAccountCard bankName="Chase" bankAccount={bankAccount} variant="selected" />
            </Stack>

            <Text p="3" fontSize='3xl'>Buttons</Text>
            <Stack p="5" direction='row' spacing={4} align='center'>
                <Button variant='solid' onClick={() => context.fireToast("Success", "testing.", "success")}>Solid Button</Button>
                <Button variant='outline' onClick={() => context.fireToast("Success", "testing.", "success")}>Outline Button</Button>
                <Button variant='ghost' onClick={() => context.fireToast("Success", "testing.", "success")}>Ghost Button</Button>
            </Stack>

            <Text p="3" fontSize='3xl'>Table</Text>
            <TableContainer>
                <Table variant='unstyled'>
                    <Thead>
                    <Tr>
                        <Th>To convert</Th>
                        <Th>into</Th>
                        <Th isNumeric>multiply by</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                    <Tr>
                        <Td>inches</Td>
                        <Td>millimetres (mm)</Td>
                        <Td isNumeric>25.4</Td>
                    </Tr>
                    <Tr>
                        <Td>feet</Td>
                        <Td>centimetres (cm)</Td>
                        <Td isNumeric>30.48</Td>
                    </Tr>
                    <Tr>
                        <Td>yards</Td>
                        <Td>metres (m)</Td>
                        <Td isNumeric>0.91444</Td>
                    </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <Text p="3" fontSize='3xl'>Select</Text>
            <Box w="250px">
                <Select useBasicStyles placeholder='Select option'>
                    <option value='option1'>Option 1</option>
                    <option value='option2'>Option 2</option>
                    <option value='option3'>Option 3</option>
                </Select>
            </Box>
        </Box>
    )
}

import React from 'react';
import { Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter, ModalOverlay, ModalContent } from '@chakra-ui/react';

const PopupUpdate = ({ isOpen, onClose, title, confirmText, confirmAction, loading, children, deleteButton }) => {
    return (
        <Modal isOpen={isOpen} toggle={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalBody>
                    {children}
                </ModalBody>
                <ModalFooter>
                    {deleteButton}
                    <Button mr="3" onClick={confirmAction} disabled={loading}>
                        {loading ? <Spinner size="sm" /> : confirmText}
                    </Button>
                    <Button variant="outline" onClick={onClose} disabled={loading}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default PopupUpdate;

import React, { useState, useEffect, useCallback } from "react"
import axios from "axios";
import { TransactionRow } from "../account/transactionRow";
import { Table, Thead, Tr, Th, Tbody, Container, Box, Flex, Stack, useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, VStack, Text, Spacer, Td, Input, ModalFooter, IconButton, HStack, NumberInput, NumberInputField } from "@chakra-ui/react";
import { BankAccountCard } from "../account/bankAccountCard";
import CustomTableContainer from "../../theme/components/tableContainer";
import { VendorSelect } from "../account/vendorDropDown";
import { AccountSelect } from "../account/accountDropDown";
import { LoadingTable, renderAmount } from "../../utils";
import { Trash2, Plus } from "react-feather"
import { Context } from "../../ContextWrapper";
import { w3cwebsocket as W3CWebSocket } from "websocket";

export const ClientBankTransactions = ({customer_id}) => {
    const [plaidItemList, setPlaidItemList] = useState([]);
    const [bankAccountList, setBankAccountList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState();
    const [selectedBankAccount, setSelectedBankAccount] = useState();
    const [accountList, setAccountList] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [nextCursor, setNextCursor] = useState(null);
    const [previousCursors, setPreviousCursors] = useState([]);
    const [balances, setBalances] = useState([]);

    useEffect(() => {
      refreshPlaidItemList(customer_id);
      refreshAccountList(customer_id);
      refreshVendorList();
      setLoading(false);
    }, [customer_id]);

    useEffect(() => {
      if (plaidItemList && plaidItemList.length > 0) {
        setSelectedItem(plaidItemList[0]);

        setBalances(plaidItemList.map((item) => (
          item.bankaccount_set.map((bankAccount) => {
            return {
              id: bankAccount.accounting_account?.id,
              balance: bankAccount.accounting_account?.current_balance,
            }
          }
        ))).flat());
      }
    }, [plaidItemList]);

    useEffect(() => {
      if (selectedItem) {
        setBankAccountList(
          selectedItem.bankaccount_set.filter((a) => a.accounting_account !== null)
        );
      }
    }, [selectedItem]);

    useEffect(() => {
      if (!selectedBankAccount && bankAccountList.length > 0) {
        setSelectedBankAccount(bankAccountList[0]);
      }
    }, [bankAccountList, selectedBankAccount]);

    useEffect(() => {
      if (selectedBankAccount) {
        refreshTransactionList(selectedBankAccount.id);
      }
    }, [selectedBankAccount]);

    const refreshPlaidItemList = (id) => {
      if (id) {
        setLoading(true);
        axios
          .get(`/api/v2/customers/${id}/plaiditems`, { headers: { 'Content-Type': 'application/json' }, withCredentials: true })
          .then((res) => {
            setPlaidItemList(res.data);
          })
          .catch((err) => console.log(err));
      }
    };

    const refreshAccountList = (customer_id) => {
      if (customer_id) {
        axios
          .get(`/api/v2/customers/${customer_id}/accounts/`, { headers: { 'Content-Type': 'application/json' }, withCredentials: true })
          .then((res) => {
            setAccountList(res.data.map((a) => ({ "value": a.id, "label": a.number + " - " + a.name })));
          })
          .catch((err) => console.log(err));
      }
    };

    const refreshVendorList = () => {
      axios
        .get("/api/v2/vendors/", { headers: { 'Content-Type': 'application/json' }, withCredentials: true })
        .then((res) => {
          setVendorList(res.data.map((v) => ({ "value": v.id, "label": v.name })));
        })
        .catch((err) => console.log(err));
    };

    const refreshTransactionList = (bankaccount_id, cursor = null) => {
      if (bankaccount_id) {
        setLoading(true);

        let cursorParam = null;
        if (cursor) {
          const urlObj = new URL(cursor);
          cursorParam = urlObj.searchParams.get('cursor');
        }

        axios
          .get(`/api/v2/bankaccounts/${bankaccount_id}/transactions/`, {
            headers: { 'Content-Type': 'application/json' },
            params: { cursor: cursorParam },
            withCredentials: true
          })
          .then((res) => {
            setTransactions(res.data.results);
            setNextCursor(res.data.next);
            setPreviousCursors(res.data.previous);
            setLoading(false);
          })
          .catch((err) => console.log(err));
      }
    };

    const replaceTransaction = (t, action = "replace") => {
      if (action === "remove") {
        setTransactions(transactions.filter(o => o.id !== t.id));
        var current = selectedBankAccount;
        current.transaction_review_count = current.transaction_review_count - 1;
        setBankAccountList(bankAccountList.map(a => a.id === current.id ? current : a));
      } else {
        setTransactions(transactions.map(o => o.id === t.id ? t : o));
      }
    };

    const selectBankAccount = (item, bankAccount) => {
      setLoading(true);
      setSelectedItem(item);
      setSelectedBankAccount(bankAccount);
      setPreviousCursors([]);
    };

    const splitTransaction = (transaction) => {
      setSelectedTransaction(transaction);
      onOpen();
    };

    const handleNextPage = () => {
      if (nextCursor) {
        refreshTransactionList(selectedBankAccount.id, nextCursor);
      }
    };

    const handlePreviousPage = () => {
      const previousCursor = previousCursors;
      if (previousCursor) {
        refreshTransactionList(selectedBankAccount.id, previousCursor);
      }
    };

    useEffect(() => {
        const updateAccount = (account_id, balance) => {
            setBalances(balances => {
                return balances.map(account => {
                  if (account.id === account_id) {
                    return {
                        ...account,
                        balance: balance,
                    }
                  } else {
                    return account
                  }
                })
            })
        }

        var host = window.location.host;
        if (host.indexOf(":3000") === -1){
            host = "wss://" + host
        } else {
            host = "ws://" + host.replace(":3000", ":8000")
        }
        const wsClient = new W3CWebSocket(`${host}/ws/${customer_id}/accountbalance/`);
        wsClient.onopen = () => {
            console.log("WebSocket Client Connected");
        };
        wsClient.onmessage = (message) => {
            const dataFromServer = JSON.parse(message.data);
            if (dataFromServer) {
              const {account_id, balance} = dataFromServer.text;

              updateAccount(account_id, balance)
            }
        };
    }, [customer_id]);

    const getAccountingBalance = (account_id) => {
      if (!account_id) {
        return null;
      }
      return balances.find(account => account.id === account_id)?.balance;
    }

    return (
      <Container maxW="100%" bg="brand.50" h='calc(91vh)'>
        <>
          {selectedTransaction &&
            <JournalSplit
            transaction={selectedTransaction}
            vendorList={vendorList}
            accountList={accountList}
            isOpen={isOpen}
            onClose={onClose}
            bankAccount={selectedBankAccount}
            replaceTransaction={replaceTransaction}
            customer_id={customer_id}
          />}
          <Flex overflowX="scroll" w={"calc(100vw - 369px)"}>
            {plaidItemList && plaidItemList.map((item) => (
              item.bankaccount_set.map((bankAccount) => (
                <BankAccountCard
                  key={bankAccount.id}
                  onClick={() => selectBankAccount(item, bankAccount)}
                  bankName={item.name}
                  bankAccount={bankAccount}
                  balance={getAccountingBalance(bankAccount.accounting_account?.id)}
                  variant={selectedBankAccount && bankAccount.id === selectedBankAccount.id ? "selected" : null}
                />
              ))
            ))}
          </Flex>
          {plaidItemList && plaidItemList.length === 0 && <div>Link bank accounts on the Plaid menu</div>}
          {plaidItemList && plaidItemList.length > 0 && bankAccountList.length === 0 && <div>Link the bank accounts to accounts on the Plaid menu</div>}
          {bankAccountList.length > 0 &&
            <CustomTableContainer>
              <Box maxHeight='calc(100vh - 450px)' m="4" bg="white" overflowX="auto">
                {loading && <LoadingTable />}
                {!loading &&
                  <Table variant="unstyled" width="100%">
                    <Thead position="sticky" top={-1} zIndex={1}>
                      <Tr>
                        <Th>Date</Th>
                        <Th>Description</Th>
                        <Th>Account</Th>
                        <Th>Vendor</Th>
                        <Th>Spent</Th>
                        <Th>Received</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {transactions && transactions.map((transaction) => (
                        <TransactionRow
                          key={transaction.id}
                          transaction={transaction}
                          bankAccount={selectedBankAccount}
                          accountList={accountList}
                          vendorList={vendorList}
                          replaceTransaction={replaceTransaction}
                          splitTransaction={splitTransaction}
                          customer_id={customer_id} />
                      ))}
                    </Tbody>
                  </Table>
                }
              </Box>
              <Stack direction='row' spacing={4} mt={4} justifyContent="center">
                {previousCursors && (
                  <Button onClick={handlePreviousPage}>
                    Previous
                  </Button>
                )}
                {nextCursor && (
                  <Button onClick={handleNextPage} disabled={!nextCursor}>
                    Next
                  </Button>
                )}
              </Stack>
            </CustomTableContainer>
          }
        </>
      </Container>
    );
};


const JournalSplit = ({ transaction, vendorList, accountList, isOpen, onClose, bankAccount, replaceTransaction, customer_id }) => {
  const [journalEntries, setJournalEntries] = useState([]);
  const [vendor, setVendor] = useState(null);
  const [index, setIndex] = useState(1);
  const [total, setTotal] = useState(0.0);
  const context = React.useContext(Context);

  const generateBlankJournalEntry = useCallback((index, amount) => {
    return {
      id: null,
      index: index,
      date: transaction.date,
      account: null,
      entry_type: transaction.debit_or_credit === "debit" ? "credit" : "debit",
      description: "",
      amount: Math.abs(amount)
    }
  }, [transaction]);

  useEffect(() => {
    setTotal(0.0);
    if (transaction.journal_entries.length > 0) {
      const entries = transaction.journal_entries.map((e, i) => {
        e.index = i;
        return e
      })
      setIndex(entries.length+1);
      setJournalEntries(entries);
    } else {
      const balanceEntry = {
        index: 999,
        account: bankAccount.accounting_account.id,
        entry_type: transaction.debit_or_credit,
        date: transaction.date,
        amount: Math.abs(transaction.amount),
        description: transaction.name,
        added: true
      };
      const newEntry = generateBlankJournalEntry(0, Math.abs(transaction.amount))
      setJournalEntries([newEntry, balanceEntry])
    }
    setVendor(transaction.vendor);
  }, [transaction, generateBlankJournalEntry, bankAccount]);

  useEffect(() => {
    setTotal(
      journalEntries
      .filter(e => e.entry_type !== transaction.debit_or_credit)
      .reduce((sum, entry) => sum + parseFloat((entry.amount || 0)), 0).toFixed(2));
  }, [journalEntries, transaction])

  const addLine = () => {
    const newEntry = generateBlankJournalEntry(index, 0)
    setJournalEntries((prevList) => [...prevList, newEntry]);
    setIndex((prev) => prev + 1);
  }

  const removeLine = (index) => {
    setJournalEntries((prevList) =>
      prevList.filter((entry) => entry.index !== index)
    );
  }

  const changeEntryAmount = (entry, e) => {
    entry.amount = Number(e.target.value).toFixed(2);
    setJournalEntries((prevList) =>
      prevList.map((e) => e.index === entry.index ? entry : e)
    );
  }

  const changeEntryDescription = (entry, e) => {
    entry.description = e.target.value;
    setJournalEntries((prevList) =>
      prevList.map((e) => e.index === entry.index ? entry : e)
    );
  }

  const updateVendor = (vendor) => {
    setVendor(vendor.value);
  }

  const updateEntryAccount = (account, {entry}) => {
    entry.account = account.value;
    setJournalEntries((prevList) =>
      prevList.map((e) => e.index === entry.index ? entry : e)
    );
  }

  const submitSplit = () => {
    if (!vendor) {
      context.fireToast("Error", "Please select vendor.", "error");
      return;
    }
    if (Number(total) !== Math.abs(transaction.amount)) {
      context.fireToast("Error", "Split amount doesn't match original amount.", "error");
      return;
    }
    if (journalEntries.length === 1) {
      context.fireToast("Error", "Add another line to split transaction.", "error");
      return;
    }
    for (let entry of journalEntries) {
      if (!entry.account) {
        context.fireToast("Error", "Please select account on all lines.", "error");
        break;
      }
      if (entry.amount <= 0) {
        context.fireToast("Error", "Invalid amount on line.", "error");
        break;
      }
    }

    var data = journalEntries.map((entry) => {
      var object = {
        customer: customer_id,
        account: entry.account,
        entry_type: entry.entry_type,
        date: entry.date,
        amount: entry.amount,
        description: entry.description,
        added: true,
      }

      if (entry.id) {
        object.id = entry.id
      }

      return object;
    })

    axios.patch(
      `/api/v2/bankaccounts/${transaction.bankaccount}/transactions/${transaction.id}/`,
      {
        vendor: vendor,
        journal_entries: data
      }
    )
    .then((res) => {
        replaceTransaction(res.data, "remove");
        closeSplitWindow();
        context.fireToast("Success", "Transaction split successfully.", "success");
    })
    .catch((err) => console.log(err));
  }

  const closeSplitWindow = () => {
    setJournalEntries([]);
    onClose();
  }

  return (
      <Modal isOpen={isOpen} onClose={closeSplitWindow} isCentered>
          <ModalOverlay />
          <ModalContent maxH="800px" maxW="1000px">
              <ModalHeader>Split Transaction</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                  <VStack align={"start"}>
                      <Text>Please input the fields below to this split transaction in it's relevant accounts.</Text>
                      <Flex w="100%">
                          <VStack spacing="0px" align={"start"}>
                              <Text m="0">Total Amount</Text>
                              <Text as="b" fontSize={"4xl"}>{renderAmount(Math.abs(transaction.amount))}</Text>
                          </VStack>
                          <Spacer />
                          <VStack spacing="2px" align={"start"}>
                              <Text m="0">Vendor</Text>
                              <VendorSelect vendors={vendorList} selectedVendorID={vendor} onChange={updateVendor} />
                          </VStack>
                      </Flex>
                      <Table variant="unstyled">
                          <Thead>
                              <Tr>
                              <Th>
                                  Account
                              </Th>
                              <Th>
                                  Description
                              </Th>
                              <Th>
                                  Amount
                              </Th>
                              <Th style={{width: "118px"}}>
                                  Action
                              </Th>
                              </Tr>
                          </Thead>
                          <Tbody>
                              {journalEntries.filter(e => e.entry_type !== transaction.debit_or_credit).map((entry) => (
                                  <Tr key={entry.index || 0}>
                                      <Td>
                                          <AccountSelect accounts={accountList} selectedAccountID={entry.account} metaData={{entry}} onChange={updateEntryAccount} />
                                      </Td>
                                      <Td>
                                          <Input type="text" value={entry.description} onChange={(e) => changeEntryDescription(entry, e)}/>
                                      </Td>
                                      <Td>
                                          <NumberInput defaultValue={entry.amount}>
                                            <NumberInputField onChange={(e) => changeEntryAmount(entry, e)} />
                                          </NumberInput>
                                      </Td>
                                      <Td>
                                      <IconButton
                                        variant='outline'
                                        colorScheme='black'
                                        aria-label='Delete line'
                                        icon={<Trash2 />}
                                        onClick={() => removeLine(entry.index)}
                                      />
                                      </Td>
                                  </Tr>
                              ))}
                          </Tbody>
                      </Table>
                      <Flex w="100%">
                          <VStack align={"start"}>
                            <Button rightIcon={<Plus />} variant={"ghost"} onClick={addLine}>
                              Add line
                            </Button>
                          </VStack>
                          <Spacer />
                          <VStack align={"start"}>
                              <HStack>
                                <Text w="150px" align={"right"}>Split Amount:</Text>
                                <Text w="90px">{renderAmount(Math.abs(total))}</Text>
                              </HStack>
                              <HStack>
                                <Text w="150px" align={"right"}>Original Amount:</Text>
                                <Text w="90px">{renderAmount(Math.abs(transaction.amount))}</Text>
                              </HStack>
                              <HStack>
                                <Text w="150px" align={"right"}>Difference:</Text>
                                <Text w="90px" color={Number(total) !== Math.abs(transaction.amount) ? "red" : "black"} >
                                  {renderAmount(Math.abs(total - Math.abs(transaction.amount)))}
                                </Text>
                              </HStack>
                          </VStack>
                      </Flex>
                  </VStack>
              </ModalBody>
              <ModalFooter>
                  <Button variant="outline" mr={3} onClick={closeSplitWindow}>Close</Button>
                  <Button onClick={submitSplit}>Save</Button>
              </ModalFooter>
          </ModalContent>
      </Modal>
  )
}

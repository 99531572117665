import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ModalOverlay, ModalContent } from '@chakra-ui/react';

const PopupDelete = ({ isOpen, onClose, title, body, confirmText, confirmAction, loading }) => {
    return (
        <Modal isOpen={isOpen} toggle={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalBody>
                    {body}
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="red" mr="3" onClick={confirmAction} disabled={loading}>
                        {loading ? 'Processing...' : confirmText}
                    </Button>{' '}
                    <Button variant="outline" onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default PopupDelete;

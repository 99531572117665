import { TableContainer } from '@chakra-ui/react';

const CustomTableContainer = (props) => {
  return (
    <TableContainer
      p="5"
      m="8"
      bg="white"
      border="3px solid"
      borderRadius="2xl"
      borderColor="white"
      boxShadow="base"
      {...props}
    >
      {props.children}
    </TableContainer>
  );
};

export default CustomTableContainer;
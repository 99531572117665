import React, { useState } from 'react';
import axios from 'axios';
import PopupDelete from '../popup_delete';
import { Button, useDisclosure } from '@chakra-ui/react';

const ClientDelete = ({ customer_id, removeCustomerFromList }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleDelete = async () => {
        setLoading(true);
        axios.delete(`/api/v2/customers/${customer_id}/`)
        .then((response) => {
            if (response.status === 200) {
                removeCustomerFromList(customer_id);
                onClose();
            } else {
                setError(response['request']['responseText'])
            }
        })
        .catch((error) => {
            console.error('There was an error deleting the Client!', error);
        })
    };

    return (
        <div>
            <Button mr='145' colorScheme="red" onClick={onOpen}>Delete</Button>
            <PopupDelete
                isOpen={isOpen}
                onClose={onClose}
                title="Confirm Delete"
                body="Are you sure you want to delete this Client?"
                confirmText="Delete"
                confirmAction={handleDelete}
                loading={loading}
                errorMessage={error}
            />
        </div>
    );
};

export default ClientDelete;

import React from "react";
import axios from "axios";
import { usePlaidLink } from 'react-plaid-link';
import { Button } from "@chakra-ui/react";
import { LinkIcon } from "@chakra-ui/icons";

export const LinkButton = ({customer_id, public_token, refreshList, setLoading}) => {
    const handleOnSuccess = (public_token, metadata) => {
        setLoading(true);
        axios.post("/api/v2/exchange_public_token/", {
            public_token: public_token,
            customer_id: customer_id
        })
        .then((res) => refreshList(customer_id));
    }

    const config = {
        onSuccess: handleOnSuccess,
        onExit: (err, metadata) => {},
        onEvent: (eventName, metadata) => {},
        token: public_token
    };
    const { open, ready } = usePlaidLink(config);

    const openPlaidLink = () => {
        if (ready) {
            open();
        }
    }

    return (
        <Button leftIcon={<LinkIcon boxSize={4}/>} onClick={openPlaidLink}>Link bank account</Button>
    )
}

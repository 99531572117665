import React, { useState, useEffect } from "react";
import axios from "axios";
import { LinkButton } from "./linkButton";

export const LinkPlaid = ({customer_id, refreshList, setLoading}) => {
    const [publicToken, setPublicToken] = useState("");

    useEffect(() => {
        createLinkToken();
    }, []);

    const createLinkToken = async () => {
        axios
            .get("/api/v2/create_link_token/", {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((res) => {
                setPublicToken(res.data.link_token);
            })
            .catch((err) => console.log(err));
    };

    return (
        <div>
            {publicToken && (
                <LinkButton customer_id={customer_id} public_token={publicToken} refreshList={refreshList} setLoading={setLoading} />
            )}
        </div>
    )
}

import React, { useEffect } from "react"
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { Center, HStack, Text, Spinner, Container } from "@chakra-ui/react";

export const ClientQuickbooksLink = () => {
    const [searchParams] = useSearchParams();

    const customer_id = localStorage.getItem("customer_id");
    const code = searchParams.get("code");
    const realmId = searchParams.get("realmId");

    useEffect(() => {
      axios
        .post(`/api/v2/client_link_quickbooks/`,
            {
              customer_id: customer_id,
              code: code,
              realm_id: realmId
            },
            {headers: {'Content-Type': 'application/json'}},
            {withCredentials: true})
        .then(() => {
          window.location.href = `/client/${customer_id}#qb`
        })
        .catch((err) => console.log(err));
    }, [customer_id, code, realmId]);

    return (
      <Container maxW="100%" height='calc(100vh - 80px)' bg="brand.50">
          <Center pt="8">
              <HStack>
                  <Text>Redirecting, please wait. &nbsp; &nbsp;</Text>
                  <Spinner color="brand.600"/>
              </HStack>
          </Center>
      </Container>
    );
}

import React, { useState, useEffect } from "react"
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faUnlink } from '@fortawesome/free-solid-svg-icons'
import { Center, Container, Spinner, Button, HStack, Text, Spacer, Box } from "@chakra-ui/react";
import { Select } from "chakra-react-select"
import { Link } from 'react-router-dom';
import { Context } from '../../ContextWrapper'

export const ClientQuickbooks = ({customer_id}) => {
    const [qbLinked, setQbLinked] = useState(false);
    const [missingMainAccounts, setMissingMainAccounts] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [authUrl, setAuthUrl] = useState("");
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        loadQuickbooksAccounts(customer_id);
    }, [customer_id]);

    const loadQuickbooksAccounts = (id, setChildLoading) => {
        axios
          .get(`/api/v2/client_quickbooks_accounts/${id}/`,
              {headers: {'Content-Type': 'application/json'}},
              {withCredentials: true})
          .then((res) => {
            setQbLinked(res.data.qb_linked);
            setMissingMainAccounts(res.data.missing_main_accounts);
            setAccounts(res.data.accounts);
            setAuthUrl(res.data.auth_url);

            localStorage.setItem("customer_id", id);

            setLoading(false);
            if (setChildLoading) {
                setChildLoading(false);
            }
          })
          .catch((err) => console.log(err));
    };

    const disconnectQuickbooks = () => {
        setLoading(true);
        axios
        .post(`/api/v2/client_link_quickbooks/`,
            {
              customer_id: customer_id,
              action: "disconnect",
            },
            {headers: {'Content-Type': 'application/json'}},
            {withCredentials: true})
        .then(() => {
          setQbLinked(false);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    };

    const createMainAccounts = () => {
        setLoading(true);
        axios
        .post(`/api/v2/create_main_qb_account/`,
            {
              customer_id: customer_id,
            },
            {headers: {'Content-Type': 'application/json'}},
            {withCredentials: true})
        .then(() => {
            loadQuickbooksAccounts(customer_id);
        })
        .catch((err) => console.log(err));
    };

    return (
        <Container maxW="100%" height='calc(100vh - 80px)' bg="brand.50">
            <Center pt="8">
                {loading && <Spinner color="brand.600"/>}
                {!qbLinked && !loading &&
                    <Link to={authUrl}>
                        <Button>Connect Quickbooks</Button>
                    </Link>
                }
            </Center>

            {qbLinked && !loading &&  (
                <>
                    <Center>
                        <HStack>
                            <Button onClick={disconnectQuickbooks}>Disconnect Quickbooks</Button>
                            {missingMainAccounts && (
                                <Button onClick={createMainAccounts}>Create main accounts</Button>
                            )}
                        </HStack>
                    </Center>
                    <Box maxHeight='calc(100vh - 184px)' p="8" m="8" bg="white" overflowX="scroll">
                        {accounts && (
                            <ul className="list-group mt-3">
                                {accounts.map((account) => (
                                    <QuickbooksAccount key={account.id} account={account} customer_id={customer_id} loadQuickbooksAccounts={loadQuickbooksAccounts} />
                                ))}
                            </ul>
                        )}
                    </Box>
                </>
            )}
        </Container>
    );
}

const QuickbooksAccount = ({account, customer_id, loadQuickbooksAccounts}) => {
    const [loading, setLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState()
    const context = React.useContext(Context);

    const accounts = account.potential_matches.map((a) => ({"value": a.Id, "label": a.Name}));

    const createAccount = (account_id) => {
        setLoading(true);
        axios
        .post(`/api/v2/create_qb_account/`,
            {
              customer_id: customer_id,
              account_id: account_id
            },
            {headers: {'Content-Type': 'application/json'}},
            {withCredentials: true})
        .then((res) => {
            if (res.status < 300){
                loadQuickbooksAccounts(customer_id, setLoading);
            }
            else{
                context.fireToast("Error", res.response.data.error, "error")
                setLoading(false);
            }
        })
        .catch((err) => {
            setLoading(false);
        });
    }

    const linkAccount = (account_id) => {
        if (!selectedItem) {
            context.fireToast("Error", "Please select account to link.", "error")
            return;
        }

        setLoading(true);
        axios
        .post(`/api/v2/link_qb_account/`,
            {
              customer_id: customer_id,
              account_id: account_id,
              qb_account_id: selectedItem.value,
            },
            {headers: {'Content-Type': 'application/json'}},
            {withCredentials: true})
        .then((res) => {
            if (res.status < 300){
                loadQuickbooksAccounts(customer_id, setLoading);
            }
            else{
                context.fireToast("Error", res.response.data.error, "error")
                setLoading(false);
            }
        })
        .catch((err) => {
            setLoading(false);
        });
    }

    return  (
        <li key={account.id} className="list-group-item">
            <HStack>
                {account.match_found && (
                    <FontAwesomeIcon icon={faLink} color="green" />
                )}
                {!account.match_found && (
                    <FontAwesomeIcon icon={faUnlink} color="red" />
                )}
                <Text>{account.number} - {account.name}</Text>
                <Spacer></Spacer>
                {!account.match_found && (
                    <>
                        {loading && (
                            <Spinner color="brand.600"/>
                        )}
                        <Box w="450px">
                            <QBAccountSelect accounts={accounts} selectedAccountID={selectedItem ? selectedItem.value : null} onChange={setSelectedItem} />
                        </Box>
                        <Button onClick={() => linkAccount(account.id)}>Link</Button>
                            <Button onClick={() => createAccount(account.id)} >Create</Button>

                    </>
                )}
            </HStack>
            {account.match_found && account.children.length > 0 && (
                <ul className="list-group mt-3">
                    {account.children.map((account) => (
                        <QuickbooksAccount key={account.id} account={account} customer_id={customer_id} loadQuickbooksAccounts={loadQuickbooksAccounts} />
                    ))}
                </ul>
            )}
        </li>
    );
}

export const QBAccountSelect = ({accounts, selectedAccountID, onChange}) => {
    const [selected, setSelected] = useState();

    useEffect(() => {
      setSelected(accounts.find((a) => a.value === selectedAccountID));
    }, [accounts, selectedAccountID]);

    const onChangeLocal = (account) => {
      setSelected(account);
      onChange(account);
    }

    return (
      <Select
          name="qbAccount"
          options={accounts}
          value={selected}
          placeholder="Search..."
          onChange={onChangeLocal}
          useBasicStyles
        />
    )
  }

import { tableAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys)

const baseStyle = definePartsStyle({
  thead: {
    background: "brand.100",
    color: 'brand.500',
  },
})

export const tableTheme = defineMultiStyleConfig({ baseStyle })

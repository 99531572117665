import React, { useState, useEffect } from "react"
import { Select } from "chakra-react-select"


export const AccountSelect = ({accounts, selectedAccountID, onChange, metaData}) => {
  const [selected, setSelected] = useState();

  useEffect(() => {
    setSelected(accounts.find((a) => a.value === selectedAccountID));
  }, [accounts, selectedAccountID]);

  const onChangeLocal = (account) => {
    setSelected(account);
    onChange(account, metaData);
  }

  return (
    <Select
        name="account"
        options={accounts}
        value={selected}
        placeholder="Select account..."
        onChange={onChangeLocal}
        useBasicStyles
      />
  )
}

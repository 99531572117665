import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys)
  const variants = {
  selected: definePartsStyle({
    container: {
      backgroundColor: 'brand.1000',
      color: "white"
    }
  })
};

export const cardTheme = defineMultiStyleConfig({ variants })

import './App.css';
import React, { useState, useEffect } from 'react';
import axios from "axios";
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import {ClientList} from "./components/client/clientList";
import {VendorsList} from "./components/vendors/vendorsList";
import Navigation from './components/navigation';
import {Logout} from './components/logout';
import { ClientDetail } from './components/client/clientDetail';
import {ClientQuickbooksLink} from './components/client/clientQuickbooksLink';
import { ChakraProvider } from '@chakra-ui/react'
import { Login } from './components/login';
import theme from './theme';
import ThemeView from './components/themeView';
import ContextWrapper from './ContextWrapper';
import { ChartOfAccounts } from './components/account/chartOfAccounts';

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const handleStorage = () => {
      if (localStorage.getItem('access_token') !== null && localStorage.getItem('access_token') !== "null") {
        setIsAuth(true);

        axios
        .get("/api/v2/profiles/",
            {headers: {'Content-Type': 'application/json'}},
            {withCredentials: true})
        .then((res) => setIsAdmin(res.data[0].is_admin))
        .catch((err) => console.log(err));
      }
      else {
        setIsAuth(false);
      }
    }

    window.addEventListener('storage', handleStorage())
    return () => window.removeEventListener('storage', handleStorage())
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <ContextWrapper>
        <BrowserRouter>
          {isAuth && <Navigation isAdmin={isAdmin}></Navigation>}
          <Routes>
            <Route path="/" element={<ClientList isAdmin={isAdmin}/>}/>
            <Route path="/client/:customer_id" element={<ClientDetail isAdmin={isAdmin}/>}/>
            {isAdmin && <Route path="/vendors" element={<VendorsList/>}/>}
            {isAdmin && <Route path="/chart-of-accounts-template" element={<ChartOfAccounts customer_id={''} template={true}/>}/>}
            <Route path="/login" element={<Login/>}/>
            <Route path="/logout" element={<Logout/>}/>
            <Route path="/qblink" element={<ClientQuickbooksLink />} />
            <Route path="/themeView" element={<ThemeView />} />
          </Routes>
        </BrowserRouter>
      </ContextWrapper>
    </ChakraProvider>
    );
}
export default App;


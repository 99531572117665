import React, { useState } from "react"
import axios from "axios";
import { Button, Tr, Td, Spinner, HStack } from "@chakra-ui/react";
import { AccountSelect } from "../account/accountDropDown";
import { VendorSelect } from "../account/vendorDropDown"
import { renderAmount } from "../../utils";

export const TransactionRow = ({ transaction, bankAccount, accountList, vendorList, replaceTransaction, splitTransaction, customer_id }) => {
    const [loading, setLoading] = useState(false);

    const isLinked = () => {
        if (transaction.journal_entries.length === 0) {
            return false;
        }
        let entriesLinked = transaction.journal_entries.every(e => e.account);
        return entriesLinked && transaction.vendor;
    }

    const getSelectedAccount = (transaction) => {
      for (let entry of transaction.journal_entries) {
        if (entry.entry_type !== transaction.debit_or_credit) {
            return entry.account;
        }
      }
    };

    const updateJournalEntry = (account) => {
        console.log(">>> updateJournalEntry");
        console.log(account);
        var data = {journal_entries: []};
        if (transaction.journal_entries.length > 0) {
            console.log("update");

            console.log("transaction before")
            console.log(transaction.debit_or_credit);
            console.log(transaction);
            var entry = transaction.journal_entries.find(e => {
                return e.entry_type !== transaction.debit_or_credit
            })
            console.log("found entry")
            console.log(entry.entry_type)
            console.log(entry);
            entry.account = account.value;
            data.journal_entries.push(entry);
        } else {
            console.log("new");
            data.journal_entries.push({
                customer: customer_id,
                account: bankAccount.accounting_account.id,
                entry_type: transaction.debit_or_credit,
                date: transaction.date,
                amount: Math.abs(transaction.amount),
                description: transaction.name,
                added: false
            })
            data.journal_entries.push({
                customer: customer_id,
                account: account.value,
                entry_type: transaction.debit_or_credit === "debit" ? "credit" : "debit",
                date: transaction.date,
                amount: Math.abs(transaction.amount),
                description: transaction.name,
                added: false
            })
        }

        console.log(data);

        updateTransaction(data);
    };

    const updateVendor = (vendor) => {
        var data = {vendor: vendor.value};
        if (transaction.journal_entries.length > 0) {
            data["id"] = transaction.journal_entries[0].id
        }
        updateTransaction(data);
    }

    const addJournalEntry = () => {
        var data = {journal_entries: []};
        for (let entry of transaction.journal_entries) {
            entry.added = true;
            data.journal_entries.push(entry);
        }
        updateTransaction(data, "remove");
    }

    const updateTransaction = (data, action="replace") => {
        setLoading(true);
        axios.patch(`/api/v2/bankaccounts/${transaction.bankaccount}/transactions/${transaction.id}/`, data)
        .then((res) => {
            replaceTransaction(res.data, action);
            setLoading(false);
        })
        .catch((err) => console.log(err));
    };

    return (
        <Tr key={transaction.id}>
            <Td>
                {transaction.date}
            </Td>
            <Td>
                {transaction.name}
            </Td>
            <Td w="350px">
                <AccountSelect accounts={accountList} selectedAccountID={getSelectedAccount(transaction)} onChange={updateJournalEntry} />
            </Td>
            <Td w="350px">
                <VendorSelect vendors={vendorList} selectedVendorID={transaction.vendor} onChange={updateVendor} />
            </Td>
            <Td>
                {transaction.debit_or_credit === "debit" && renderAmount(Math.abs(transaction.amount))}
            </Td>
            <Td>
                {transaction.debit_or_credit === "credit" && renderAmount(Math.abs(transaction.amount))}
            </Td>
            <Td>
                <HStack p="0" direction='row' spacing={2} align='center'>
                    <Button variant='outline' onClick={() => splitTransaction(transaction)}>Split</Button>
                    {isLinked() && !loading && <Button colorScheme="brand" onClick={addJournalEntry}>Add</Button>}
                    {loading && <Spinner color="brand.600"/>}
                </HStack>
            </Td>
        </Tr>
    )
}

import React, { useEffect, useState } from "react"
import { Box, Button, Checkbox, Container, Flex, FormControl, FormLabel, Heading, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Stack, Table, Tbody, Td, Text, Thead, Tr, useDisclosure } from "@chakra-ui/react"
import axios from "axios";
import CustomTableContainer from "../../theme/components/tableContainer";
import { LoadingTable, renderAmount } from "../../utils";
import { Context } from "../../ContextWrapper";
import { AccountSelect } from "../account/accountDropDown";
import { w3cwebsocket as W3CWebSocket } from "websocket";

export const ClientGeneralLedger = ({customer_id}) => {
    const [loading, setLoading] = useState(true);
    const [loadingEntries, setLoadingEntries] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [accountList, setAccountList] = useState([]);
    const [journalEntryList, setJournalEntryList] = useState([]);
    const context = React.useContext(Context);
    const [allSelected, setAllSelected] = useState(false);
    const { isOpen: isReclassifyOpen, onOpen: onReclassifyOpen, onClose: onReclassifyClose } = useDisclosure()


    useEffect(() => {
        const updateAccount = (account_id, balance) => {
            setAccountList(accountList => {
                return accountList.map(account => {
                    if (account.id === account_id) {
                        console.log(`updating ${account.number} to ${balance}`);
                        return {
                            ...account,
                            current_balance: balance,
                        }
                    } else {
                        return account;
                    }
                })
            })
        }

        var host = window.location.host;
        if (host.indexOf(":3000") === -1){
            host = "wss://" + host
        } else {
            host = "ws://" + host.replace(":3000", ":8000")
        }
        console.log(`${host}/ws/accountbalance/`);
        const wsClient = new W3CWebSocket(`${host}/ws/${customer_id}/accountbalance/`);
        wsClient.onopen = () => {
            console.log("WebSocket Client Connected");
        };
        wsClient.onmessage = (message) => {
            console.log(">>> web socket message");
            const dataFromServer = JSON.parse(message.data);
            if (dataFromServer) {
              console.log(dataFromServer);
              const {account_id, balance} = dataFromServer.text;
              console.log(account_id);
              console.log(balance);

              updateAccount(account_id, balance)
            }
        };
    }, [customer_id]);

    useEffect(() => {
        const refreshAccountList = (customer_id) => {
            axios
            .get(`/api/v2/customers/${customer_id}/accounts/`,
                {headers: {'Content-Type': 'application/json'}},
                {withCredentials: true})
            .then((res) => {
                setAccountList(res.data);
            })
            .catch((err) => console.log(err));
        };

        refreshAccountList(customer_id);
        setLoading(false);
    }, [customer_id]);

    const selectedEntries = journalEntryList.filter(entry => entry.selected);
    const accounts = accountList
        .filter(a => a.id !== selectedAccount?.id)
        .map((a) => ({ "value": a.id, "label": a.number + " - " + a.name }))

    useEffect(() => {
        if (selectedAccount) {
            setAllSelected(false);
            setLoadingEntries(true);
            const refreshJournalEntryList = (customer_id) => {
                axios
                .get(`/api/v2/customers/${customer_id}/journalentries/?account=${selectedAccount.id}`,
                    {headers: {'Content-Type': 'application/json'}},
                    {withCredentials: true})
                .then((res) => {
                    setJournalEntryList(res.data.results);
                })
                .catch((err) => console.log(err));
            };
            refreshJournalEntryList(customer_id);
            setLoadingEntries(false);
        }
    }, [selectedAccount, customer_id, context])

    const exportGeneralLedger = () => {
        context.fireToast("Error", "Not implemented yet", "error");
    }

    const selectAllEntries = (e) => {
        setAllSelected(e.target.checked);
        setJournalEntryList(journalEntryList.map(entry => {
            entry.selected = e.target.checked;
            return entry;
        }))
    }

    const selectEntry = (entry, e) => {
        entry.selected = e.target.checked;
        setJournalEntryList(journalEntryList.map(o => o.id === entry.id ? entry : o));
    }

    const startReclassify = () => {
        onReclassifyOpen();
    }

    const handleReclassify = (account_id) => {
        const journalEntries = selectedEntries.map((entry) => {
            entry.account = account_id;
            return entry
        });


        axios.patch(`/api/v2/customers/${customer_id}/bulkjournalentries/`, journalEntries)
        .then(() => {
            onReclassifyClose();
            setAllSelected(false);
            setJournalEntryList(journalEntryList.filter(entry => !entry.selected));
            context.fireToast("Success", "Transaction(s) reclassified.", "success");
        })
        .catch((err) => console.log(err));

    }

    return (
        <Container maxW="100%" bg="brand.50" h='calc(91vh)' >
            <Stack direction='column' spacing={2} align='center'>
                <CustomTableContainer width="100%">
                    <ReclassifyModal isOpen={isReclassifyOpen} loading={loading} onClose={onReclassifyClose} accounts={accounts} handleReclassify={handleReclassify} />
                    <Flex pb="5">
                        <Box>
                            <Heading as='h3' size='lg'>General Ledger</Heading>
                        </Box>
                        <Spacer />
                        <Box>
                        <Stack direction='row' spacing={6} align='center'>
                            {selectedEntries.length > 0 && <Button onClick={startReclassify}>Reclassify</Button>}
                            <Button onClick={exportGeneralLedger}>Export General Ledger</Button>
                        </Stack>
                        </Box>
                    </Flex>
                    <HStack>
                        <Box maxHeight='calc(100vh - 350px)' w="535px" bg="white" overflowX="hidden">
                            {loading && <LoadingTable />}
                            {accountList &&
                                <Table variant="unstyled" size='sm'>
                                    <Thead position="sticky" top={-1} zIndex={1}>
                                        <Tr>
                                            <Td>Account</Td>
                                            <Td>Balance</Td>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                    {accountList.filter((a) => a.is_main_account || !a.parent).map(account => (
                                        <AccountRow
                                            key={account.id}
                                            account={account}
                                            selectedAccount={selectedAccount}
                                            setSelectedAccount={setSelectedAccount}
                                            accountList={accountList}
                                        />
                                    ))}
                                    </Tbody>
                                </Table>
                            }
                        </Box>
                        <Box h='calc(100vh - 350px)' w="100%" bg="white" overflowX="auto">
                            {loadingEntries && <LoadingTable />}
                            {selectedAccount && journalEntryList && (
                                <Table variant="unstyled" size='sm'>
                                    <Thead position="sticky" top={-1} zIndex={1}>
                                        <Tr>
                                            <Td w="40px"><Checkbox isChecked={allSelected} onChange={selectAllEntries} /></Td>
                                            <Td>Date</Td>
                                            <Td>Description</Td>
                                            <Td>Memo</Td>
                                            <Td>Debit</Td>
                                            <Td>Credit</Td>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {journalEntryList.map(entry => (
                                            <Tr key={entry.id}>
                                                <Td><Checkbox isChecked={entry.selected} onChange={(e) => selectEntry(entry, e)} /></Td>
                                                <Td>{entry.date}</Td>
                                                <Td>{entry.description}</Td>
                                                <Td>{entry.memo}</Td>
                                                <Td>{entry.entry_type === "debit" && renderAmount(entry.amount)}</Td>
                                                <Td>{entry.entry_type === "credit" && renderAmount(entry.amount)}</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            )}
                            {selectedAccount && journalEntryList.length ===0 && <Text>No journal entries for account</Text>}
                        </Box>
                    </HStack>
                </CustomTableContainer>
            </Stack>
        </Container>
    )
}

const AccountRow = ({account, selectedAccount, setSelectedAccount, accountList}) => {
    return (
        <>
            <Tr
                background={account.id === selectedAccount?.id ? "brand.200" : null}
                onClick={() => setSelectedAccount(account)}
            >
                <Td>
                    <div style={{paddingLeft: account.is_main_account ? 0 : 15}}>
                        {account.number} - {account.name}
                    </div>
                </Td>
                <Td>
                    {renderAmount(account.current_balance)}
                </Td>
            </Tr>
            {accountList.filter((a) => a.parent === account.id).map((inner_account) => (
                <AccountRow
                    key={inner_account.id}
                    account={inner_account}
                    selectedAccount={selectedAccount}
                    setSelectedAccount={setSelectedAccount}
                    accountList={accountList}
                />
            ))}
        </>
    )
}

const ReclassifyModal = ({isOpen, onClose, loading, accounts, handleReclassify}) => {
    const [account, setAccount] =  useState(null);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Reclassify entries</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl isRequired>
                        <FormLabel>Move to account</FormLabel>
                        <AccountSelect accounts={accounts} onChange={setAccount} />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button variant="outline" mr={3} onClick={onClose}>Cancel</Button>
                    <Button onClick={() => handleReclassify(account.value)} isDisabled={loading} >
                        {loading ? "Processing..." : "Reclassify"}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

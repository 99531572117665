import axios from "axios";

if (process.env.REACT_APP_DOMAIN) {
  axios.defaults.baseURL = process.env.REACT_APP_DOMAIN;
}

let refresh = false;
axios.interceptors.response.use(resp => resp, async error => {
  if (error.response.status === 401 && !refresh) {
     refresh = true;
     const response = await
           axios.post('/api/v2/token/refresh/',
                      {
                        refresh:localStorage.getItem('refresh_token')
                      },
                      {
                        headers: {'Content-Type': 'application/json'}
                      },
                      {
                        withCredentials: true
                      });
    if (response.status === 200) {
       localStorage.setItem('access_token', response.data.access);
       localStorage.setItem('refresh_token', response.data.refresh);
       return axios(error.config);
    } else {
      window.location.href = '/login';
      localStorage.setItem('access_token', null);
      window.dispatchEvent(new Event('storage'));
    }
  }
  refresh = false;
  return error;
});

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("access_token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

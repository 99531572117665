import React, { useState, useEffect } from "react"
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
    Table, Thead, Tbody, Th, Tr, Td, Container, Box, HStack,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter,
    ModalBody, ModalCloseButton, FormControl, FormLabel, Input,
    Checkbox, Button, Spinner, Textarea, Heading, Flex
  } from "@chakra-ui/react";
import { AccountSelect } from "./accountDropDown";
import { Select } from "chakra-react-select"
import CustomTableContainer from "../../theme/components/tableContainer";
import Context from "../../ContextWrapper";
import { AddIcon } from "@chakra-ui/icons";
import { renderAmount } from "../../utils";

export const ChartOfAccounts = ({ customer_id, template = false }) => {
    const [loading, setLoading] = useState(true);
    const [accountList, setAccountList] = useState([]);
    const [accountTypeList, setAccountTypeList] = useState([]);
    const [accountSubTypeList, setAccountSubTypeList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [currentAccount, setCurrentAccount] = useState({});
    const context = React.useContext(Context);

    useEffect(() => {
        const refreshAccountList = (customer_id) => {
            const url = getAccountsUrl(customer_id, template);
            axios
            .get(url,
                {headers: {'Content-Type': 'application/json'}},
                {withCredentials: true})
            .then((res) => {
                setAccountList(res.data);
            })
            .catch((err) => console.log(err));
        };

        const refreshAccountTypeList = () => {
            axios
            .get(`/api/v2/accounttypes/`,
                {headers: {'Content-Type': 'application/json'}},
                {withCredentials: true})
            .then((res) => {
                setAccountTypeList(res.data.map(t => ({"value": t.id, "label": t.name})));
            })
            .catch((err) => console.log(err));
        };

        const refreshAccountSubTypeList = () => {
            axios
            .get(`/api/v2/accountsubtypes/`,
                {headers: {'Content-Type': 'application/json'}},
                {withCredentials: true})
            .then((res) => {
                setAccountSubTypeList(res.data.map(t => ({"value": t.id, "label": t.name, "type": t.type})));
            })
            .catch((err) => console.log(err));
        };

        refreshAccountList(customer_id);
        refreshAccountTypeList();
        refreshAccountSubTypeList();
        setLoading(false);
    }, [customer_id, template]);

    const toggle = () => {
        setCurrentAccount({});
        setIsModalOpen(!isModalOpen);
    };

    const startCreateAccount = () => {
        setCurrentAccount({});
        setIsModalOpen(true);
    }

    const startUpdateAccount = (account) => {
        setCurrentAccount(account);
        setIsModalOpen(true);
    }

    const toggleDelete = () => {
        setCurrentAccount({});
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };

    const startDeleteAccount = (account) => {
        setCurrentAccount(account);
        setIsDeleteModalOpen(true);
    }

    function getAccountsUrl(customer_id, template) {

        if (!template) {
            return `/api/v2/customers/${customer_id}/accounts/`;
        } else {
            return `/api/v2/accounts/`;
        }
    }

    const handleSubmit = (event, account) => {
        event.preventDefault();
        if (account.is_main_account) {
            account.parent = null;
        }

        let url = getAccountsUrl(customer_id, template);

        if (account.id) {

            url += `${account.id}/`;

            axios.patch(url, account)
            .then((res) => {
                if (res.status < 300){
                    setAccountList(accountList.map(o => o.id === res.data.id ? res.data : o));
                } else {
                    context.fireToast("Error", res.request.responseText, "error");
                }
                setLoading(false);
                toggle();
            })
            .catch((err) => console.log(err));
        } else {
            axios.post(url, account)
            .then((res) => {
                if (res.status < 300){
                    setAccountList([...accountList,res.data]);
                } else {
                    context.fireToast("Error", res.request.responseText, "error");
                }
                setLoading(false);
                toggle();
            })
            .catch((err) => console.log(err));
        }
        setCurrentAccount({});
    };

    const handleDelete = (account) => {

        let url = getAccountsUrl(customer_id, template);

        url += `${account.id}/`;

        axios.delete(url)
        .then((res) => {
            if (res.status < 300){
                setAccountList(accountList.filter(o => o.id !== account.id));
            } else {
                context.fireToast("Error", res.request.responseText, "error");
            }
            setLoading(false);
            toggleDelete();
        })
        .catch((err) => console.log(err));
    };

    return (
        <Container maxW="100%" bg="brand.50" h='calc(91vh)' >
            {loading && <FontAwesomeIcon className="float-end fa-spin" icon={faSpinner} color="green" />}
            <div className="row justify-content-center pb-4 pt-4">
                <div className="col-auto">
                    <Button onClick={startCreateAccount} leftIcon={<AddIcon boxSize={3}/>}>Create Account</Button>
                </div>
            </div>
            <CustomTableContainer>
            <AccountModal isOpen={isModalOpen} toggle={toggle} title="Create Account" account={currentAccount} accountList={accountList} accountTypeList={accountTypeList} accountSubTypeList={accountSubTypeList} loading={loading} handleSubmit={handleSubmit} />
            <AccountDeleteModal colorScheme="red" isOpen={isDeleteModalOpen} toggle={toggleDelete} account={currentAccount} loading={loading} handleDelete={handleDelete} />
            <Flex pb="5">
                <Box>
                    <Heading as='h3' size='lg'>Chart of Accounts</Heading>
                </Box>
            </Flex>
            <Box maxHeight='calc(100vh - 350px)' bg="white" overflowX="auto">
                <Table variant="unstyled">
                    <Thead position="sticky" top={-1} zIndex={1}>
                        <Tr>
                            <Th>
                                Number
                            </Th>
                            <Th>
                                Name
                            </Th>
                            {!template &&
                                <Th>
                                    Current Balance
                                </Th>
                            }
                            <Th>
                                Type
                            </Th>
                            <Th>
                                Sub Type
                            </Th>
                            <Th>
                                Action
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {accountList.filter((a) => a.is_main_account || !a.parent).map((account) => (
                            <AccountRow
                                key={account.id}
                                account={account}
                                accountList={accountList}
                                accountTypeList={accountTypeList}
                                accountSubTypeList={accountSubTypeList}
                                startUpdateAccount={startUpdateAccount}
                                startDeleteAccount={startDeleteAccount}
                                template={template}
                                customer_id={customer_id}
                            />
                        ))}
                    </Tbody>
                </Table>
            </Box>
            </CustomTableContainer>
        </Container>
    )
}

const AccountRow = ({ account, accountList, accountTypeList, accountSubTypeList, startUpdateAccount, startDeleteAccount, template, level = 0, customer_id }) => {
    var typeText = "";
    const type = accountTypeList.find((at) => at.value === account.type);
    if (type) {
        typeText = type.label;

    }
    
    var subTypeText = "";
    const subType = accountSubTypeList.find((at) => at.value === account.sub_type);
    if (subType) {
        subTypeText = subType.label;
    }

    const isEditable = template || account.customer === customer_id;

    return (
        <>
            <Tr key={account.id}>
                <Td>{account.number}</Td>
                <Td><div style={{paddingLeft: level * 35}}>{account.number} {account.name}</div></Td>

                {!template &&
                    <Td>
                        {renderAmount(account.current_balance)}
                    </Td>
                }
                <Td>{typeText}</Td>
                <Td>{subTypeText}</Td>
                <Td>
                    {isEditable && (
                        <HStack spacing={2}>
                            <Button onClick={() => startUpdateAccount(account)}>Edit</Button>
                            <Button colorScheme="red" onClick={() => startDeleteAccount(account)}>Delete</Button>
                        </HStack>
                    )}
                </Td>
            </Tr>
            {accountList.filter((a) => a.parent === account.id).map((inner_account) => (
                <AccountRow
                    account={inner_account}
                    accountList={accountList}
                    accountTypeList={accountTypeList}
                    accountSubTypeList={accountSubTypeList}
                    level={level + 1}
                    startUpdateAccount={startUpdateAccount}
                    startDeleteAccount={startDeleteAccount}
                    template={template}
                    customer_id={customer_id}
                />
            ))}
        </>
    )
}

const AccountDeleteModal = ({isOpen, toggle, account, loading, handleDelete}) => {
    return (
        <Modal isOpen={isOpen} onClose={toggle}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Delete Account</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                Are you sure you want to delete {account.name}?
            </ModalBody>
            <ModalFooter>
            <Button
                colorScheme="red"
                onClick={() => handleDelete(account)}
                isDisabled={loading}
            >
                {loading ? "Processing..." : "Delete"}
            </Button>
            <Button onClick={toggle} ml={3}>
                Cancel
            </Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
    )
}

const AccountModal = ({isOpen, toggle, title, account, accountList, accountTypeList, accountSubTypeList, loading, handleSubmit}) => {
    const confirmText = !account.id ? "Create" : "Update";
    const [accountData, setAccountData] = useState({});

    const formattedAccountList = accountList.map(account => {
        return {
            value: account.id,
            label: account.number + " - " + account.name
        };
    });

    useEffect(() => {
        setAccountData(account);
    }, [account]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAccountData({ ...accountData, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setAccountData({ ...accountData, [name]: checked });
    };

    const handleSelectParent = (parent) => {
        setAccountData({ ...accountData, parent: parent.value });
    }

    const handleSelectType = (type) => {
        setAccountData({ ...accountData, type: type.value});
    }

    const handleSelectSubType = (sub_type) => {
        setAccountData({ ...accountData, sub_type: sub_type.value})
    }

    return (
        <Modal isOpen={isOpen} onClose={toggle}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>{title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <form id="accountForm" onSubmit={(e) => handleSubmit(e, accountData)}>
                <FormControl display="flex" alignItems="center">
                    <Checkbox
                        name="is_main_account"
                        isChecked={accountData.is_main_account}
                        onChange={handleCheckboxChange}
                    >
                        Main Account
                    </Checkbox>
                </FormControl>
                    {!accountData.is_main_account && (
                    <FormControl>
                        <FormLabel htmlFor="parent_id">Parent Account</FormLabel>
                        <AccountSelect accounts={formattedAccountList} selectedAccountID={accountData.parent} onChange={handleSelectParent} />
                    </FormControl>
                    )}
                <FormControl>
                    <FormLabel htmlFor="number">Number</FormLabel>
                    <Input
                        type="text"
                        name="number"
                        defaultValue={accountData.number}
                        onChange={handleInputChange}
                        required
                    />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="name">Name</FormLabel>
                    <Input
                        type="text"
                        name="name"
                        defaultValue={accountData.name}
                        onChange={handleInputChange}
                        required
                    />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="type">Type</FormLabel>
                    <TypeSelect types={accountTypeList} selectedTypeID={accountData.type} onChange={handleSelectType} />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="sub_type">Sub Type</FormLabel>
                    <SubTypeSelect type={accountData.type} subTypes={accountSubTypeList} selectedSubTypeID={accountData.sub_type} onChange={handleSelectSubType} />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="notes">Notes</FormLabel>
                    <Textarea
                        name="notes"
                        defaultValue={accountData.notes}
                        onChange={handleInputChange}
                    />
                </FormControl>
            </form>
            </ModalBody>
            <ModalFooter>
                <Button onClick={toggle} isDisabled={loading} colorScheme="blackAlpha">
                    Cancel
                </Button>
                <Button
                    type="submit"
                    form="accountForm"
                    isDisabled={loading}
                    ml={3}
                >
                    {loading ? <Spinner size="sm" /> : confirmText}
                </Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
    )
}

export const TypeSelect = ({types, selectedTypeID, onChange}) => {
    const [selected, setSelected] = useState();

    useEffect(() => {
      setSelected(types.find((a) => a.value === selectedTypeID));
    }, [types, selectedTypeID]);

    const onChangeLocal = (type) => {
      setSelected(type);
      onChange(type);
    }

    return (
      <Select
          name="account"
          options={types}
          value={selected}
          placeholder={`Select type...`}
          onChange={onChangeLocal}
          useBasicStyles
        />
    )
}

export const SubTypeSelect = ({subTypes, type, selectedSubTypeID, onChange}) => {
    const [selected, setSelected] = useState();

    useEffect(() => {
      setSelected(subTypes.find((a) => a.value === selectedSubTypeID));
    }, [subTypes, selectedSubTypeID]);

    const onChangeLocal = (type) => {
      setSelected(type);
      onChange(type);
    }

    return (
      <Select
          name="account"
          options={subTypes.filter(s => s.type === type)}
          value={selected}
          placeholder={`Select sub type...`}
          onChange={onChangeLocal}
          useBasicStyles
        />
    )
}

const Icon = {
    baseStyle: {
        // color: "white"
    },
    variants: {
        white: {
            color: "white"
        }
    }
};

export default Icon;

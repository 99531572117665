import React, { useState } from 'react';
import axios from 'axios';
import PopupUpdate from '../popup_update';
import { Button, useDisclosure, Input, FormControl, FormLabel } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

export const PlaidItemCreate = ({ customer_id , addPlaidItemToList }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setLoading(true);
        setName('')

        try {
            const response = await axios.post(`/api/v2/customers/${customer_id}/plaiditems/`, { name, customer_id });
            if (response.status === 201) { 
                addPlaidItemToList(response.data); 
                onClose();
            } else {
                setError('Unexpected response from the server.');
            }
        } catch (error) {
            setError('There was an error submitting the form!');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Button leftIcon={<AddIcon boxSize={3}/>} onClick={onOpen}>Create Bank</Button>
            <PopupUpdate
                isOpen={isOpen}
                onClose={onClose}
                title="Create Bank"
                confirmText="Create"
                confirmAction={handleSubmit}
                loading={loading}
                errorMessage={error}
            >
                <form onSubmit={handleSubmit}>
                    <FormControl id="name" isRequired>
                        <FormLabel>Name</FormLabel>
                        <Input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </FormControl>
                    <Input
                        type="text"
                        id="customer_id"
                        value={customer_id}
                        required
                        readOnly
                        hidden
                    />
                </form>
            </PopupUpdate>
        </div>
    );
};

import React, { useState } from 'react';
import axios from 'axios';
import { Input , FormControl, FormLabel } from '@chakra-ui/react';
import PopupUpdate from '../popup_update';
import { Context } from "../../ContextWrapper";


export const VendorsQuickCreate = ({ addVendorToList, isOpen, onClose, initialName }) => {
  const [name, setName] = useState(initialName);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const context = React.useContext(Context);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setLoading(true);

    const formData = new FormData();
    formData.append('name', name);
    formData.append('is_active', true);

    try {
      const response = await axios.post('/api/v2/vendors/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.status === 201) {
        const newVendor = {
          value: response.data.id,
          label: response.data.name,
        };
        addVendorToList(newVendor);
        setName('');
        onClose();
      } else {
        context.fireToast("Error", response.request.responseText, "error")
      }
    } catch (error) {
      setError('There was an error submitting the form!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <PopupUpdate
      isOpen={isOpen}
      onClose={onClose}
      title="Create Vendor"
      body="Please fill in the details below to create a new vendor."
      confirmText="Create"
      confirmAction={handleSubmit}
      loading={loading}
      errorMessage={error}
    >
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <FormControl id="name" isRequired>
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </FormControl>
      </form>
    </PopupUpdate>
  );
};

import React, { useState, useEffect } from "react";
import { Select } from "chakra-react-select";
import { HStack, Box, Button } from "@chakra-ui/react";
import { VendorsQuickCreate } from "../vendors/vendorQuickCreate";

export const VendorSelect = ({ vendors, selectedVendorID, onChange }) => {
  const [selected, setSelected] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [isQuickCreateOpen, setIsQuickCreateOpen] = useState(false);

  useEffect(() => {
    setSelected(vendors.find((a) => a.value === selectedVendorID));
  }, [vendors, selectedVendorID]);

  const onChangeLocal = (vendor) => {
    setSelected(vendor);
    onChange(vendor);
  };

  const onInputChange = (inputValue) => {
    let result = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    setSearchInput(result);
  };

  const addVendorToList = (newVendor) => {
    vendors.push(newVendor);
    setSelected(newVendor);
    onChange(newVendor);
  };

  const handleOpenQuickCreate = () => {
    setIsQuickCreateOpen(true);
  };

  const handleCloseQuickCreate = () => {
    setIsQuickCreateOpen(false);
  };

  return (
    <HStack spacing={2}>
      <Box width="200px">
        <Select
          name="vendor"
          options={vendors}
          value={selected}
          placeholder="Select vendor..."
          onChange={onChangeLocal}
          onInputChange={onInputChange}
          useBasicStyles
          flex="1"
          noOptionsMessage={() =>
            <Button onClick={handleOpenQuickCreate}>Add {searchInput}</Button>
          }
        />
      </Box>
      {isQuickCreateOpen && (
        <VendorsQuickCreate
          addVendorToList={addVendorToList}
          isOpen={isQuickCreateOpen}
          onClose={handleCloseQuickCreate}
          initialName={searchInput}
        />
      )}
    </HStack>
  );
};

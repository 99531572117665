import React, { useState } from 'react';
import axios from 'axios';
import { Input, Text, Tooltip } from '@chakra-ui/react';
import { Context } from '../../ContextWrapper';

const EditQuickbooksName = ({ bankAccountId, initialName }) => {
  const [name, setName] = useState(initialName || '...');
  const [isEditing, setIsEditing] = useState(false);
  const context = React.useContext(Context);

  const handleDoubleClick = () => {
    setIsEditing(true);
    if (name === '...') {
      setName('');
    }
  };

  const handleBlur = async () => {
    setIsEditing(false);

    if (!name.trim() || name !== initialName) {
        try {
            await axios.patch(
            `/api/v2/bankaccounts/${bankAccountId}/`,
            { quickbooks_name: name }
            );
            context.fireToast("Success", "Quickbooks name updated.", "success")
        } catch (error) {
            console.error('Error updating name:', error);
            setName(initialName || '...');
            context.fireToast("Error", "Quickbooks update error.", "error")
        }
        if (!name.trim()) {
            setName('...');
        }
    }
  };

  const handleChange = (e) => {
    setName(e.target.value);
  };

  return (
    <div>
      {isEditing ? (
        <Input
          type="text"
          value={name}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          size="sm"
        />
      ) : (
        <Tooltip label="Double click to edit" fontSize="md">
          <Text
            onDoubleClick={handleDoubleClick}
            cursor="pointer"
          >
            {name}
          </Text>
        </Tooltip>
      )}
    </div>
  );
};

export default EditQuickbooksName;

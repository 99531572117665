import React, { useState } from 'react';
import axios from 'axios';
import PopupDelete from '../popup_delete';
import { Button, useDisclosure } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons'


const ClientPLaidItemDelete = ({ customer_id , plaiditem_id, removePlaidItemFromList }) => {
    const [loading, setLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure()

    const handleDelete = async () => {
        setLoading(true);
        try {
            const response = await axios.delete(`/api/v2/customers/${customer_id}/plaiditems/${plaiditem_id}/`);
            if (response.status === 200) {
                removePlaidItemFromList(plaiditem_id);
                onClose();
            } else {
                console.error('Unexpected response code:', response.status);
            }
        } catch (error) {
            console.error('There was an error deleting the Bank!', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Button colorScheme="red" onClick={onOpen}><DeleteIcon boxSize={3}/></Button>
            <PopupDelete
                isOpen={isOpen}
                onClose={onClose}
                title="Confirm Delete"
                body="Are you sure you want to delete this Bank?"
                confirmText="Delete"
                confirmAction={handleDelete}
                loading={loading}
            />
        </div>
    );
};

export default ClientPLaidItemDelete;

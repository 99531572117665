import React, { useState } from 'react';
import axios from 'axios';
import PopupUpdate from '../popup_update';
import {
    Button, FormControl, FormLabel, Input, FormHelperText, Box, Image, Icon, useDisclosure, useColorModeValue, Text
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons'
import { FaImage } from 'react-icons/fa';
import { Context } from '../../ContextWrapper';

export const ClientCreate = ({ addCustomerToList }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const context = React.useContext(Context);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImage(file);
        setImagePreview(URL.createObjectURL(file));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setLoading(true);

        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('is_active', true);
        if (image) {
            formData.append('logo', image);
        }

        try {
            const response = await axios.post(`/api/v2/customers/`, formData);
            if (response.status === 201) {
                addCustomerToList(response.data);
                setImage(null);
                setImagePreview(null);
                setEmail('');
                setName('');
                onClose();
            } else {
                setImage('');
                setImagePreview(null);
                context.fireToast("Error", response.request.responseText, "error");
            }
        } catch (error) {
            setError('There was an error submitting the form!');
        } finally {
            setLoading(false);
        }
    };

    const borderColor = useColorModeValue("blue.500", "blue.700");
    const backgroundColor = useColorModeValue("blue.50", "gray.700");

    return (
        <div>
            <Button leftIcon={<AddIcon />} onClick={onOpen}>Create New Client</Button>
            <PopupUpdate
                isOpen={isOpen}
                onClose={onClose}
                title="Create Client"
                confirmText="Create"
                confirmAction={handleSubmit}
                loading={loading}
                errorMessage={error}
            >
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <FormControl id="name" isRequired>
                    <FormLabel>Name</FormLabel>
                        <Input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </FormControl>
                    <FormControl id="email" isRequired>
                        <FormLabel>Email</FormLabel>
                        <Input
                            type="text"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </FormControl>
                    <FormControl id="logo">
                        <FormLabel>Facility Logo</FormLabel>
                        <Box
                            borderWidth="2px"
                            borderStyle="dashed"
                            borderColor={borderColor}
                            borderRadius="lg"
                            backgroundColor={backgroundColor}
                            width="100%"
                            maxW="400px"
                            height="150px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                            cursor="pointer"
                            _hover={{ borderColor: "blue.300" }}
                            position="relative"
                        >
                            <Icon as={FaImage} boxSize={10} color="gray.400" />
                            <Text mt={2}>
                                <Text as="span" color="blue.500">
                                    Click to upload
                                </Text>{" "}
                                or drag and drop
                            </Text>
                            <Text fontSize="sm" color="gray.500">
                                Maximum file size of 1 MB
                            </Text>
                            <Input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                opacity="0"
                                position="absolute"
                                width="100%"
                                height="100%"
                                cursor="pointer"
                            />
                        </Box>
                        <FormHelperText>Choose an image file to upload.</FormHelperText>
                        {imagePreview && (
                            <Box boxSize="100px" mt={2}>
                                <Image
                                    src={imagePreview}
                                    alt="Image preview"
                                    objectFit="cover"
                                    borderRadius="md"
                                />
                            </Box>
                        )}
                    </FormControl>
                </form>
            </PopupUpdate>
        </div>
    );
};

import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys)

const baseStyle = definePartsStyle({
  list: {
    border: 'none',
    bg: 'brand.500',
  },
  item: {
    color: 'gray.200',
    bg: 'brand.500',
    _hover: {
      bg: 'brand.700',
    },
    _focus: {
      bg: 'brand.600',
    },
  },
})

export const menuTheme = defineMultiStyleConfig({ baseStyle })
